<template>
  <div class="user-search-container">
    <div class="user-search-info">
      <p class="user-search-info-header"><span class="user-search-header-info-bold">"{{ searchTerm }}"</span> için arama sonuçları</p>
      <p class="user-search-info-description"><span @click="$router.push('/search?term='+ searchTerm);">İş ilanlarında ara '{{ searchTerm }}'</span></p>
    </div>
    <div class="user-search-results-section">
      <div class="user-search-results-header-section">
        <h4 class="user-search-results-header">Kullanıcı Profilleri</h4>
      </div>
      <loader v-if="!pageLoaded"></loader>
      <div v-else class="user-search-results-list-section">
        <ul class="user-search-results-list">
          <li v-for="user in users" class="user-search-results-list-item">
            <router-link :to="'/'+user.username" style="text-decoration: none">
              <div class="user-search-results-list-item-detail">
                <img class="user-search-results-list-item-detail-user-image" :src="user.avatarURL" alt="user-image">
                <p class="user-search-results-list-item-detail-user-name">{{ user.username }}</p>
                <span class="user-search-results-list-item-detail-user-title">{{user.sellerTitle}}</span>
                <div v-if="user.rating" class="user-search-results-list-item-detail-user-rate">
                  <star-rating
                    v-model="user.rating"
                    :max-rating="5"
                    :star-size="18"
                    :show-rating="false"
                    inactive-color="#bfc8d2"
                    active-color="#ffb700"
                    :read-only="true"></star-rating>
                  <span class="user-search-results-list-item-detail-user-rate-count">({{user.ratingCount}})</span>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
        <div v-if="seemorediv && !seemoreLoading" @click="getUsers(offset, false)" style="cursor: pointer;padding-bottom:10px;font-size: 16px; font-weight: 600;text-align: center; color: #2d3640;">
          Daha fazla gör +
        </div>
        <loader v-if="seemoreLoading && pageLoaded"></loader>
        <div v-if="total === 0" style="text-align: center;padding: 10px">
          Not found
        </div>
        <div class="hiddendiv"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating';
  import Loader from "../../components/toolbox/loader.vue";

  export default {
    components: {
      Loader,
      StarRating
    },
    data() {
      return {
        users: [],
        limit: 20,
        offset: 0,
        total: null,
        searchTerm: null,
        pageLoaded: false,
        seemoreLoading: false,
        seemoreDiv : false
      }
    },

    methods: {
      prepareHtmlTitleAndDescription(term) {
        this.$store.state.indexHTMLTitle = `${term} içinde Freelancerlar | Bionluk`;
        this.$store.state.indexHTMLMetaDescription = `${term} içinde Freelancerlar | Bionluk`;
      },

      getUsers(offset, count) {
        if (offset > 0) {
          this.seemoreLoading = true
        }
        this.searchUsers({term: this.searchTerm, limit: this.limit, offset, count})
          .then(result => {
            this.offset += this.limit;
            this.users = [...this.users, ...result.results];
            if (result.count) {
              this.total = result.count;
            }

            this.seemorediv = this.offset < this.total;
            this.pageLoaded = true;
            this.seemoreLoading = false;
            this.prepareHtmlTitleAndDescription(this.searchTerm);

            if (offset === 0) {
              this.Helper.trackEvents.pageView(this.Helper.userSearchPageProps(this.total, this.route.query));
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }
    },

    watch: {
      'route.query.term' : function (newValue, oldValue) {
        this.searchTerm = (newValue && newValue !== 'undefined') ? newValue: null;
        this.pageLoaded = false;
        this.seemoreDiv = false;
        this.users = [];
        this.offset = 0;
        this.getUsers(0, true);
      }
    },

    created() {
      this.searchTerm = this.route.query.term;
      this.getUsers(0, true);
    }
  }
</script>

<style scoped lang="scss">

  .user-search-container{
    background-color: #f4f5f7;
  }
  .user-search-info{
    display: flex;
    justify-content: center;
    flex-direction: column;
    .user-search-info-header{
      font-size: 32px;
      font-weight: 300;
      text-align: center;
      color: #4b4f52;

      padding-top: 50px;
      .user-search-header-info-bold{
        font-weight: 500;
      }
    }
    .user-search-info-description{
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #2d3640;
      text-decoration: underline;
      padding: 23px 0 40px;
      span {
        cursor: pointer;
      }
    }
  }

  .user-search-results-section{
    margin: 0 10.4%;
    overflow: hidden;
    .user-search-results-header-section{
      display: flex;
      justify-content: center;
      .user-search-results-header{
        font-weight: 600;
        color: #2d3640;
        padding: 22px;
        position: relative;

        &:before, &:after{
          content: "";
          position: absolute;
          top: 50%;
          right: 100%;
          width: 50vw;
          height: 2px;
          background-color: #dde2e7;
        }

        &:after{
          left: 100%;
        }
      }
    }
    .user-search-results-list-section{
      margin-top: 5px;
    }

    .user-search-results-list{
      padding: 0;
      display: flex;
      flex: 1 1 0;
      flex-wrap: wrap;
      justify-content: center;
    }

    .user-search-results-list-item{
      display: inline-block;
      margin-right: 9px;
      margin-left: 9px;
    }

    .user-search-results-list-item-detail{
      margin: 20px 0;
      display: inline-flex;
      align-items: center;
      flex-direction: column;
      background-color: #ffffff;
      border: solid 1px #eaedf2;
      width: 165px;
      height: 248px;
      .user-search-results-list-item-detail-user-image{
        width: 100px;
        height: 100px;
        border-radius: 100px;
        background-color: #eceded;
        margin: 20px;
      }
      .user-search-results-list-item-detail-user-name{
        font-size: 16px;
        font-weight: 600;
        color: #242424;
        margin: 3px;
      }
      .user-search-results-list-item-detail-user-title{
        font-size: 14px;
        font-style: italic;
        color: #8b95a1;
        height: 18px;
      }
      .user-search-results-list-item-detail-user-rate{
        margin-top: 15px;
        display: flex;
        align-items: center;
      }
      .user-search-results-list-item-detail-user-rate-count{
        font-size: 14px;
        color: #8b95a1;
      }
    }
  }



</style>
